const { AUTH0_DOMAIN } = require('./helpers/constants');
var tenants = require('./tenants');

function getRedirectUriPath (developmentMode) {
    return !!developmentMode
        ? '/'
        : '/auth-callback/';
}
function getSignOutUrl () {
    return window.location.origin;
}

function getOidcSetting (developmentMode, tenantConfiguration) {
    var result = {
        domain: tenantConfiguration.domain,
        clientID: tenantConfiguration.clientId,
        audience: 'https://api.cimpress.io/',
        redirectUri: window.location.origin + getRedirectUriPath(developmentMode),
        responseType: 'code',
        scope: 'openid offline_access',
        leeway: 60,
    };
    if (typeof developmentMode === 'object') {
        if (typeof developmentMode['clientID'] === 'string') {
            result.clientID = developmentMode['clientID'];
           
            // in case the clientId was provided but the domain wasn't provided, then we fall back to using auth0.
            if (typeof developmentMode['domain'] !== 'string') {
                result.domain = AUTH0_DOMAIN;
            }
        }
        if (typeof developmentMode['redirectUriOrigin'] === 'string') {
            result.redirectUri = developmentMode['redirectUriOrigin'].replace(/\/$/, "") + getRedirectUriPath(developmentMode);
        }
        if (typeof developmentMode['domain'] === 'string') {
            result.domain = developmentMode['domain'];
        }
        if (typeof developmentMode['audience'] === 'string') {
            result.audience = developmentMode['audience'];
        }
        if (typeof developmentMode['scope'] === 'string') {
            result.scope = developmentMode['scope'];
        }
    }
    return result;
}
function create (developmentMode) {
    var tenantConfiguration = tenants.getTenantConfiguration();
    return {
        developmentMode: !!developmentMode,
        storageTokenKey: 'vp-access-token',
        storageRefreshKey: 'vp-refresh-token',
        storageVerifierKey: 'vp-code-verifier',
        oidc: getOidcSetting(developmentMode, tenantConfiguration),
        tokenRefreshInterval: 30 * 60 * 1000,
        connection: tenantConfiguration.connection,
        site: tenantConfiguration.defaultSite || 'Vistaprint',
        signOutUrl: getSignOutUrl(),
        redirectAfterSignOutCallback: () => { return window.location.origin; },
        // below 2 urls are kept for backward compatibility, to be removed in the future
        anonymousEndpoint: 'https://anon.oauth.cimpress.io/v0/7AeLhPS4Ab7fztoMVAchwX',
        cimpressAnonymousEndpoint: 'https://anon.oauth.cimpress.io/v0/pA8i267E3bgjL8Tomhmrew',
        // new values
        anonymousBaseEndpoint: 'https://anon.oauth.cimpress.io/v1/',
        cimpressAnonKey: 'pA8i267E3bgjL8Tomhmrew',
        authFrontAnonymousEndpoint: 'https://oauth.cimpress.io/v2/token'
    }
}

module.exports = {
    create: create
};
