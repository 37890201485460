function getFragments () {
    if (!window.location.hash && !window.location.search) { return {}; }

    var locationObj = (window.location.search
        && (window.location.search.indexOf('code=') !== -1 || window.location.search.indexOf('raso=') !== -1))
        ? window.location.search
        : window.location.hash;
    return locationObj
        .substring(1)
        .split('&')
        .reduce(function (prev, cur) {
            var kv = cur.split('=');
            prev[kv[0]] = kv[1];
            return prev;
        }, {});
}

module.exports = {
    getFragments: getFragments
}