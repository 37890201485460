var Events = require('./events');
var cookies = require('./cookies/cookies');
var StorageNotification = require('./storage/storageNotification.js');
var jwt = require('./helpers/jwt');

var timer;
function AnonymousSession(storage, options) {
    if (!storage) {
        throw new Error('[AnonymousSession] Error: Storage layer not provided.')
    }
    if (!options) {
        throw new Error('[AnonymousSession] Error: Options not provided.')
    }

    this.storage = storage;
    this.options = options;
    this.cookies = cookies;
    this.events = new Events();
    this.cimpressAnonKey = options.cimpressAnonKey;
    this.anonymousBaseEndpoint = options.anonymousBaseEndpoint;
    this.hasInitialEventBeenPublished = false;
    this.storageNotification = new StorageNotification(storage, options);
    this.retryCount = 2
}

AnonymousSession.prototype.init = function (session) {
    this.session = session;
    this.storageNotification.subscribeOnce('user', this.storageChanged.bind(this));
    
    this.checkRefresh();
}

AnonymousSession.prototype.checkRefresh = function () {
    if (this.signedInOtherWindow) {
        this.signedInOtherWindow = null;
        return;
    }

    var token = this.storage.get(this.options.storageTokenKey);
    var anon = this.cookies.getAnonymousIdentityState();

    // No data for Anonymous token refresh present.
    if (!anon) {
        this.createAnonymousIdentity();
        return;
    }

    // Token is missing but all data for token refresh is present.
    if (!token) {
        this.refreshAnonymousIdentity(anon);
        return;
    }

    // Inspect existing token
    var tokenAge = (Date.now() - token.timestamp) / 1000;
    var isValidToken = jwt.isValidToken(token.value)

    if (!isValidToken) {
        // Anonymous token is expired.
        this.refreshAnonymousIdentity(anon);
    } else {
        // Anonymous token is valid, schedule next refresh.
        if (!this.hasInitialEventBeenPublished) {
            this.events.publishAnonymousIdentity(token.value);
            this.hasInitialEventBeenPublished = true;
        }
        this.schedule(this.options.tokenRefreshInterval - tokenAge);
    }
}

AnonymousSession.prototype.createAnonymousIdentity = function () {
    var xhr = new XMLHttpRequest();
    xhr.onload = this.createAnonymousIdentityCallback.bind(this, xhr);
    xhr.onerror = this.createAnonymousIdentityCallback.bind(this, xhr);

    xhr.open("POST", `${this.anonymousBaseEndpoint}${this.cimpressAnonKey}/token`);
    xhr.send();
}

AnonymousSession.prototype.refreshAnonymousIdentity = function (anon) {
    var xhr = new XMLHttpRequest();
    xhr.onload = this.refreshAnonymousIdentityCallback.bind(this, xhr, anon);
    xhr.onerror = this.refreshAnonymousIdentityCallback.bind(this, xhr, anon);

    // override anon endpoint
    let anonEndpoint = `${this.anonymousBaseEndpoint}${anon.anonKey || this.cimpressAnonKey}`;
    
    xhr.open("POST", anonEndpoint + '/identities/' + anon.id + '/token');
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.send(JSON.stringify({ validationKey: anon.nonce }));
}

AnonymousSession.prototype.createAnonymousIdentityCallback = function (xhr) {
    if (xhr.status !== 201) { //201=Created
        console.log('Unable to create token from Cimpress anon service. Status:' + xhr.status);
        //Try it Again
        const retryCodes = [408, 429, 500, 502, 503, 504, 522, 524];
        if (retryCodes.includes(xhr.status)) {
            if (this.retryCount === 0) {
                this.events.publishUserIdentityError({ message: xhr.responseText, statusCode: xhr.status })
                return;
            }
            this.retryCount = this.retryCount - 1;
            this.scheduleCreateAnonymousIdentity();
        }
        return;
    }
    // override anonkey
    this.processTokenResponse(xhr.response, this.cimpressAnonKey);
}

AnonymousSession.prototype.refreshAnonymousIdentityCallback = function (xhr, anon) {
    if (xhr.status !== 200) {
        console.log('Unable to refresh token from Cimpress anon service. Status:' + xhr.status);
        //Try it again
        this.schedule();
        return;
    }
    this.processTokenResponse(xhr.response, anon.anonKey || this.cimpressAnonKey);
}

AnonymousSession.prototype.processTokenResponse = function (response, anonKey) {
    if (this.signedInOtherWindow) {
        this.signedInOtherWindow = null;
        return;
    }
    var body = JSON.parse(response);

    var token = {
        id: body.anonymousId,
        nonce: body.validationKey,
        value: body.anonymousToken
    };
    this.storage.set(
        this.options.storageTokenKey,
        'anonymous',
        token.value
    );

    this.cookies.setAnonymousIdentityState(token.id, token.nonce, anonKey);

    this.schedule();
    this.events.publishAnonymousIdentity(token.value);
}

AnonymousSession.prototype.schedule = function (interval) {
    if (timer) {
        clearTimeout(timer);
    }
    var nextTick = interval || this.options.tokenRefreshInterval;
    timer = setTimeout(this.checkRefresh.bind(this), nextTick);
}

AnonymousSession.prototype.scheduleCreateAnonymousIdentity = function () {
    var nextTick = 30 * 1000;
    setTimeout(this.createAnonymousIdentity.bind(this), nextTick);
}

AnonymousSession.prototype.storageChanged = function () {
    this.signedInOtherWindow = true;
    this.hasInitialEventBeenPublished = false;
    this.session.init();
}

module.exports = AnonymousSession;
