var Glob = (function() {
    var globalObject = {};

    // https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
    if (typeof window !== 'undefined') {
        globalObject = window;
    }

    globalObject.vp = globalObject.vp || {};
    globalObject.vp.auth = globalObject.vp.auth || {};
    globalObject.vp.auth.deps = globalObject.vp.auth.deps || null;
    globalObject.vp.auth.cache = globalObject.vp.auth.cache || null;

    return globalObject;
})();

function getDeps() {
    return Glob.vp.auth.deps;
}

function setDeps(deps) {
    if (!deps) { return; }

    Glob.vp.auth.deps = deps;
}

function isDepsInitialized() {
    return Glob.vp.auth.deps !== null;
}

function getCache() {
    return Glob.vp.auth.cache;
}

function setCache(value) {
    if (!value) { return; }

    Glob.vp.auth.cache = value;
}

function isCacheInitialized() {
    return Glob.vp.auth.cache !== null;
}

module.exports = {
    getDeps: getDeps,
    setDeps: setDeps,
    isDepsInitialized: isDepsInitialized,
    getCache: getCache,
    setCache: setCache,
    isCacheInitialized: isCacheInitialized
}
