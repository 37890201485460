var jwt = require('./helpers/jwt');
var Event = require('./helpers/customEvent');

function Events() {
    this.target = window;
}

Events.prototype.publish = function (type, value) {
    var event = new Event(type, value);
    this.target.dispatchEvent(event);
}

Events.prototype.publishUserIdentity = function (token) {
    var claims = jwt.parse(token);
    this.publish('userIdentity', {
        detail: {
            profile: claims,
            accessToken: token,
            isSignedIn: true,
            canonicalId: jwt.getCanonicalId(claims),
            recentAnonymousIds: jwt.getRecentAnonymousIds(claims),
            authorizationHeader: `Bearer ${token}`,
            authorization: {
                type: "Bearer",
                token: token
            }
        },
    });
}

Events.prototype.publishAnonymousIdentity = function (token) {
    var claims = jwt.parse(token);
    this.publish('userIdentity', {
        detail: {
            profile: claims,
            accessToken: token,
            isSignedIn: false,
            canonicalId: jwt.getCanonicalId(claims),
            authorizationHeader: `Bearer ${token}`,
            authorization: {
                type: "Bearer",
                token: token
            }
        }
    });
}

Events.prototype.publishEmptyIdentity = function () {
    this.publish('userIdentity', {
        detail: {
            profile: {},
            accessToken: null,
            isSignedIn: false,
            canonicalId: null,
            authorizationHeader: null,
            authorization: {}
        }
    });
}

Events.prototype.publishUserIdentityError = function (errorObj) {
    this.publish('userIdentityError', {
        detail: errorObj
    })
}

module.exports = Events;
