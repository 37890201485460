var oidc = require('auth0-js');

var location = require('../helpers/location');
var cookies = require('../cookies/cookies');
var cimpressSession = require('../helpers/cimpressSession');
var utils = require('../helpers/utils');

function promoteIdentity(settings, storage, token, config) {
    // Save token
    var fragments = location.getFragments();
    cookies.setPresumedLoginState();
    // Delete anonymous cookie
    var anon = cookies.getAnonymousIdentityState();

    if (anon) {
        cookies.deleteAnonymousIdentityState();
        // delete the anon session cookie
        cookies.deleteSessionCookie();
    }

    if (token) {
        storage.set(settings.storageTokenKey, 'user', token.accessToken);
        storage.set(settings.storageRefreshKey, 'user', token.refreshToken);
        cookies.setAuthCookie(token.refreshToken);
        cookies.setConfigCookie(config.clientId, config.domain);

        if (token.alternativeAccessToken) {
            cookies.setSessionCookie(token.alternativeAccessToken);
        }

        storage.delete(settings.storageVerifierKey);
    } else {
        storage.set(settings.storageTokenKey, 'user', fragments.access_token);
    }
}

function redirect(url) {
    console.error("[callback] Debug:redirectURL " + encodedUrl);
    window.location = url;
}

module.exports = function (storage, settings) {
    // this handles redirect after sign out for development mode
    var fragments = location.getFragments();
    if (settings.developmentMode && fragments.raso) {
        redirect(fragments.raso)
    }

    //This applies callback only when on the root page of the domain or in configured redirectUriOrigin
    if (window.location.origin + window.location.pathname !== settings.oidc.redirectUri) {
        return;
    }

    if (window.location.search) {
        if (fragments.code && fragments.state) {
            var codeVerifier = storage.get(settings.storageVerifierKey);
            var parsedState = utils.parseState(fragments.state);
            var requireSession = parsedState.requireSession;
            var clientId = parsedState.clientId || settings.oidc.clientID
            var domain = parsedState.domain || settings.oidc.domain

            var authentication = new oidc.Authentication({
                domain: domain,
                clientID: clientId,
                audience: settings.oidc.audience,
                scope: 'openid offline_access'
            });

            authentication.oauthToken(
                {
                    grantType: 'authorization_code',
                    clientId: clientId,
                    codeVerifier: codeVerifier.value,
                    code: fragments.code,
                    redirect_uri: parsedState.redirectUrl
                },
                (error, tokenObject) => {
                    if (error) {
                        console.error(error);
                    } else {
                        promoteIdentity(
                            settings,
                            storage,
                            tokenObject,
                            { clientId, domain }
                        );
                        if (requireSession && tokenObject && !tokenObject.alternativeAccessToken) {
                            cimpressSession.createSession(parsedState, settings, tokenObject);
                        } else {
                            redirect(parsedState.redirectUrl);
                        }
                    }
                }
            );
        }
    } else if (window.location.hash) {
        if (fragments.access_token) {
            promoteIdentity(
                settings,
                storage
            );
        }

        if (fragments.state) {
            redirect(utils.parseState(fragments.state).redirectUrl);
        }
    }
};
