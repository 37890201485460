var allowedParentDomains = ['vistaprint', 'vista', 'proshopnext', 'ourproshop'];

function getDomainForCookie(hostname) {
    if (!hostname) { return null; }

    // Because there are domains like co.uk or com.au we have to take
    // last 3 sections of current domain to find out on which site we are.
    var lastThreeHostSegments = hostname.split('.')
                                        .slice(-3);

    for(var index = lastThreeHostSegments.length-1; index >= 0; index-=1) {
        var currentSegment = lastThreeHostSegments[index];
        if(allowedParentDomains.indexOf(currentSegment) >= 0) {
            return lastThreeHostSegments.slice(index).join('.');
        }
    }
    return hostname;
}

module.exports = {
    resolveCookieDomain: getDomainForCookie
}
