function ReturnUrlBuilder(location) {
    if (!location || !location.href || !location.origin) {
        throw new Error('[ReturnUrlBuilder]: Parameter \'location\' has to be specified and valid.');
    }

    this.location = location;
    this.additionalQueryParams = []
    this.additionalHashParams = []
}

ReturnUrlBuilder.prototype.withAdditionalQueryParam = function (key, value) {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    this.additionalQueryParams.push([encodedKey, encodedValue]);
    return this;
}

ReturnUrlBuilder.prototype.withAdditionalHashParam = function (key, value) {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    this.additionalHashParams.push([encodedKey, encodedValue]);
    return this;
}

ReturnUrlBuilder.prototype.build = function() {
    if (this.additionalQueryParams.length === 0 &&
        this.additionalHashParams.length === 0 &&
        this.location.search.indexOf('askUserToLogin') < 0) {
        // No modifications are needed for the current URL.
        return this.location.href;
    }

    let url = this.location.origin;
    let searchParam = this.location.search ? removeURLSearchParameter(this.location.search) : '';
    url = appendPath(url, this.location.pathname);
    url = appendOriginalParams(url, searchParam);
    url = appendAdditionalParams(
        url,
        this.additionalQueryParams,
        getQueryParamsSeparator(searchParam)
    );
    url = appendOriginalParams(url, this.location.hash);
    url = appendAdditionalParams(
        url,
        this.additionalHashParams,
        getHashParamsSeparator(searchParam)
    );

    return url;
}

function removeURLSearchParameter (searchParameter, parameterToRemove = 'askUserToLogin') {
    var urlParts = searchParameter.split('?');
    if (urlParts.length >= 2) {
        var prefix = encodeURIComponent(parameterToRemove) + '=';
        var pars = urlParts[1].split(/[&;]/g);

        for (var i = pars.length - 1; i >= 0; i--) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return searchParameter;
}

function appendPath(url, path) {
    return path ? url + path : url;
}

function appendOriginalParams(url, queryParams) {
    return queryParams ? url + queryParams : url;
}

function appendAdditionalParams(url, params, separator) {
    if (params.length === 0) {
        return url;
    }

    const additionalParams = params
        .map((kv) => `${kv[0]}=${kv[1]}`)
        .join('&');

    return `${url}${separator}${additionalParams}`;
}

function getQueryParamsSeparator(originalQueryParams) {
    return originalQueryParams ? '&' : '?';
}

function getHashParamsSeparator(originalHashParams) {
    return originalHashParams ? '&' : '#';
}

module.exports = ReturnUrlBuilder;
