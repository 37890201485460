function InMemoryStorage() {
    this.storage = {};
};

InMemoryStorage.prototype.getItem = function(key) {
    return this.storage[key];
};

InMemoryStorage.prototype.setItem = function(key, data) {
    this.storage[key] = data;
};

InMemoryStorage.prototype.removeItem = function(key) {
    this.storage[key] = undefined;
};

InMemoryStorage.prototype.addStorageChangedInOtherWindowEventListener = function (){
    // In memory data cannot be changed in the other window
}

InMemoryStorage.prototype.removeStorageChangedInOtherWindowEventListener = function (){
    // In memory data cannot be changed in the other window
}

module.exports = InMemoryStorage;
