function trackSignIn(caller, callback) {
    trackEvent('Sign-in Flow Initiated', caller);

    // Ensure tracking event had enought time to be sent.
    if (callback) {
        setTimeout(callback, 300);
    }
}

function trackEvent(eventName, caller) {
    if (!window || !window.tracking || typeof window.tracking.track !== 'function') {
        console.warn('Tracking-js not found. Cannot track sign-in event.');
        return;
    }

    window.tracking.track(eventName, { caller: caller });
}

module.exports = {
    trackSignIn: trackSignIn
}
