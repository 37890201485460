var Events = require('./events');
var cookies = require('./cookies/cookies');
var StorageNotification = require('./storage/storageNotification.js');
var jwt = require('./helpers/jwt');
var utils = require('./helpers/utils');

var timer;
var cimpressSession = require('./helpers/cimpressSession');
function Session(anon, storage, options) {
    if (!storage) {
        throw new Error('[Session] Error: Storage layer not provided.')
    }
    if (!utils.isAdfsConnection({ options }) && !anon) {
        throw new Error('[Session] Error: Anonymous fallback not provided.')
    }
    if (!options) {
        throw new Error('[Session] Error: Options not provided.')
    }

    this.anon = anon;
    this.storage = storage;
    this.options = options;
    this.events = new Events();
    this.storageNotification = new StorageNotification(storage, options);
    this.hasInitialEventBeenPublished = false;
    this.bindedEventListenerCallback = this.eventListenerCallback.bind(this)
}

Session.prototype.init = function () {
    // If cross-site SSO is disabled (_pls cookie enabled) and there is no _pls cookie,
    // clean local storage and initialize anonymous session.
    var enablePresumedLoginState = !this.options.enableCrossSiteSso;
    if (enablePresumedLoginState && !cookies.getPresumedLoginState()) {
        return this.cleanUp();
    }

    this.storageNotification.subscribeOnce('anonymous', this.storageChanged.bind(this));
    this.checkRefresh();
    window.addEventListener('visibilitychange', this.bindedEventListenerCallback);
}

Session.prototype.eventListenerCallback = function () {
    if (document.visibilityState === 'visible') {
        this.checkRefresh()
    }
}

Session.prototype.refreshPkce = function (refreshToken, domain, clientId) {
    var xhr = new XMLHttpRequest();
    xhr.onload = this.refreshTokenCallback.bind(this, xhr, {clientId, domain});
    xhr.onerror = this.refreshTokenCallback.bind(this, xhr, {clientId, domain});

    xhr.open("POST", `https://${domain}/oauth/token`);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.send(JSON.stringify({ grant_type: 'refresh_token', refresh_token: refreshToken, client_id: clientId }));
}

Session.prototype.refreshTokenCallback = function (xhr, configObject) {
    if (this.signedOutInOtherWindow) {
        this.signedOutInOtherWindow = null;
        return;
    }

    if (xhr.status !== 200) {
        return this.cleanUp();
    }

    var body = JSON.parse(xhr.response)

    // if a reference_token was provided, do not create/update old sessions
    if (body.alternative_access_token) {
        cookies.setSessionCookie(body.alternative_access_token);
    } else if (this.options.requireSession) {
        cimpressSession.updateSession(body.access_token); // update session with new access_token
    }

    // Store new access token.
    this.storage.set(this.options.storageTokenKey, 'user', body.access_token);
    this.storage.set(this.options.storageRefreshKey, 'user', body.refresh_token);
    cookies.setAuthCookie(body.refresh_token);
    cookies.setConfigCookie(configObject.clientId, configObject.domain);
    
    // Drop _pls cookie so its validity is extended
    cookies.setPresumedLoginState();
    this.events.publishUserIdentity(body.access_token);

    // Schedule next refresh cycle.
    this.schedule();
}

Session.prototype.schedule = function (interval) {
    if (timer) {
        clearTimeout(timer);
    }
    var nextTick = interval || this.options.tokenRefreshInterval;
    timer = setTimeout(this.checkRefresh.bind(this), nextTick);
}

Session.prototype.checkRefresh = function () {
    if (this.signedOutInOtherWindow) {
        this.signedOutInOtherWindow = null;
        return;
    }

    var token = this.storage.get(this.options.storageTokenKey);
    var refreshTokenFromStorage = this.storage.get(this.options.storageRefreshKey);
    var refreshTokenFromCookie = cookies.getAuthCookie();

    var refreshToken = refreshTokenFromCookie || (refreshTokenFromStorage && refreshTokenFromStorage.value)

    // no ways to refresh the token, initiate anon session
    if (!refreshToken) {
        return this.cleanUp();
    }
    
    var configCookie = cookies.getConfigCookie();
    const clientID = (configCookie && configCookie.clientId) || this.options.oidc.clientID
    const domain = (configCookie && configCookie.domain) || this.options.oidc.domain

    // No User token present, try to refresh OIDC session.
    if (!token || token.type === 'anonymous') {
        this.refreshPkce(refreshToken, domain, clientID);
        return;
    }

    // Inspect existing token
    var tokenAge = (Date.now() - token.timestamp) / 1000;

    if (!jwt.isValidToken(token.value)) {
        // User token is expired/near expiry.
        this.refreshPkce(refreshToken, domain, clientID)
    } else {
        // User token is valid, schedule next refresh.
        if (!this.hasInitialEventBeenPublished) {
            // publish initial event
            this.events.publishUserIdentity(token.value);
            this.hasInitialEventBeenPublished = true;
        }
        this.schedule(this.options.tokenRefreshInterval - tokenAge > 0
            ? this.options.tokenRefreshInterval - tokenAge
            : this.options.tokenRefreshInterval);
    }
}

Session.prototype.cleanUp = function () {
    this.sessionEnded = true;
    this.hasInitialEventBeenPublished = false;

    let token = this.storage.get(this.options.storageTokenKey);
    
    // delete local storage if the information is present for the user
    if (token && token.type === 'user') {
        this.storage.delete(this.options.storageTokenKey);
        this.storage.delete(this.options.storageRefreshKey);
        
        // delete cookies
        cookies.deleteAuthCookie();
        cookies.deleteConfigCookie();
        cookies.deletePresumedLoginState();

        var sessionId = cookies.getSessionCookie();
        if (sessionId) {
            cimpressSession.closeSession(sessionId);
            cookies.deleteSessionCookie();
        }
    }

    window.removeEventListener('visibilitychange', this.bindedEventListenerCallback);
    if (this.anon) {
        this.anon.init(this);
    } else {
        this.events.publishEmptyIdentity();
    }
}

Session.prototype.storageChanged = function () {
    if (this.sessionEnded) {
        this.sessionEnded = null;
        return;
    }
    this.signedOutInOtherWindow = true; 
    this.hasInitialEventBeenPublished = false;
    window.removeEventListener('visibilitychange', this.bindedEventListenerCallback);
    if (this.anon) {
        this.anon.init(this);
    } else {
        this.events.publishEmptyIdentity();
    }
}

module.exports = Session;
