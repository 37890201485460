var cookies = require('../cookies/cookies');
var SESSIONS_BASE_URL = 'https://sessions.cimpress.io/v1/sessions';
var consts = require('./constants');

// the following are used with sessions-service (sessions.cimpress.io)

// on successful sign-in, create a new session with the access_token 
// set sessionId in 'vpsession' cookie
function createSession(parsedState, settings, token) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        if (this.status === 201) {
            var body = JSON.parse(this.response);
            var sessionId = body.sessionId;
            cookies.setSessionCookie(sessionId);
        }
        window.location = decodeURIComponent(parsedState.redirectUrl); // handle redirect
    };
    xhr.onerror = function () {
        console.log('Unable to create a new session from Sessions service having the origin' + window.location.origin);
        window.location = decodeURIComponent(parsedState.redirectUrl);
    };

    xhr.open("POST", SESSIONS_BASE_URL);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.send(JSON.stringify({
        origin: window.location.origin,
        clientId: parsedState.clientId || settings.oidc.clientID,
        accessToken: token.accessToken
    }));
}

// when auth-js library refreshes a token, call sessions-service to let it know about the new access token  
function updateSession(token) {
    var sessionId = cookies.getSessionCookie();
    if (sessionId) {
        var sessionDetails = sessionId.match(consts.SESSION_ID_REGEX);
        if (sessionDetails) {
            var xhr = new XMLHttpRequest();
            xhr.onload = updateSessionCallback;
            xhr.onerror = updateSessionCallback;
            xhr.passToken = token;

            xhr.open("PUT", `${SESSIONS_BASE_URL}/${sessionId}/tokens`);
            xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhr.send(JSON.stringify({ accessToken: token }));
        }
    }
}

function updateSessionCallback() {
    if (this.status !== 200) {
        console.log('Unable to update session from Sessions service. Status:' + this.status);
        const retryCodes = [408, 429, 500, 502, 503, 504, 522, 524];
        if (retryCodes.includes(xhr.status)) {
            if (this.retryCount === 0) {
                return;
            }
            this.retryCount = this.retryCount - 1;
            scheduleUpdateSession(this.passToken);
        }
        return;
    }
}

function scheduleUpdateSession(token) {
    var nextTick = 120 * 1000;  // 2 minutes
    setTimeout(function () {
        updateSession(token)
    }, nextTick);
}

// close user session and unset cookie
function closeSession(sessionId) {
    var sessionDetails = sessionId.match(consts.SESSION_ID_REGEX);
    if (sessionDetails) {
        var xhr = new XMLHttpRequest();
        xhr.onload = closeSessionCallback;
        xhr.onerror = closeSessionCallback;
        xhr.open("POST", `${SESSIONS_BASE_URL}/${sessionId}/lifecycle/logout`);
        xhr.send();
    }
}

function closeSessionCallback() {
    if (this.status !== 204) {
        console.log('Unable to close session from Sessions service. Status:' + this.status);
        return;
    }
}

module.exports = {
    createSession: createSession,
    updateSession: updateSession,
    closeSession: closeSession
};
