const { EXPIRTY_TIME } = require("./constants");

function parse(jwt) {
    var parts = jwt.split('.');
    try {
        var claimsPart = parts[1];
        return JSON.parse(atob(claimsPart));
    }
    catch (e) {
        return null;
    }
}

function getCanonicalId(profile){
    return profile['https://claims.cimpress.io/canonical_id'];
}

function getRecentAnonymousIds(profile){
    return profile['https://claims.cimpress.io/was'];
}
function isValidToken(token){
    const decodedToken = parse(token)
    const remainingTokenAge = decodedToken['exp'] - Math.round(Date.now() / 1000);
    return remainingTokenAge > EXPIRTY_TIME;
}

module.exports = {
    parse: parse,
    getCanonicalId: getCanonicalId,
    getRecentAnonymousIds: getRecentAnonymousIds,
    isValidToken: isValidToken
};
