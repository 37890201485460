var browserLocalStorage = require('./browserLocalStorage');
var InMemoryStorage = require('./inMemoryStorage');

function Storage() {
    if (browserLocalStorage.isAvailable() === true) {
        this.storage = browserLocalStorage.getStorage();
    } else {
        this.storage = new InMemoryStorage();
    }
}

Storage.prototype.set = function (key, type, value, data) {
    if (typeof value !== 'string') {
        throw new Error('[Storage.prototype.set] Error: Value must be of type \'string\'.')
    }
    var valueObj = {
        type: type,
        value: value,
        timestamp: Date.now(),
        data: data
    };
    var json = JSON.stringify(valueObj);
    this.storage.setItem(key, json);
};

Storage.prototype.get = function (key) {
    var json = this.storage.getItem(key);

    if (!json) { return null; }

    try {
        return JSON.parse(json);
    }
    catch (_) {
        this.storage.removeItem(key);
        return null;
    }
};

Storage.prototype.delete = function (key) {
    this.storage.removeItem(key);
}

Storage.prototype.addStorageChangedInOtherWindowEventListener = function (callback) {
    this.storage.addStorageChangedInOtherWindowEventListener(callback);
}

Storage.prototype.removeStorageChangedInOtherWindowEventListener = function (callback) {
    this.storage.removeStorageChangedInOtherWindowEventListener(callback);
}

module.exports = Storage;
