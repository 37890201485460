var cookieDomainResolver = require('./cookiesDomainResolver');

var plsCookieName = '_pls';
var anonCookieName = 'anon';
var optimizelyCookieName = 'optimizelyEndUserId';
var testCookieName = "testUserId";

var authCookieName = "vpauth";
var sessionCookieName = "vpsession";
var configCookieName = "vpconfig";

function setCookie(key, value, expires) {
    var domain = cookieDomainResolver.resolveCookieDomain(
        window.location.hostname
    );

    document.cookie =
        key + "=" + value + ";expires=" + expires + ";path=/;domain=" + domain;
}

function getCookie(key, parseJSON = true) {
    let cookieValue = null;
    try {
        document.cookie.split(";").every(item => {
            const cookieItem = item.trim();
            const [firstValue, ...restValues] = cookieItem.split("=");
            if (firstValue === key) {
                cookieValue = restValues.join("=");
                return false;
            }
            return true;
        });
        return cookieValue && parseJSON ? JSON.parse(cookieValue) : cookieValue;
    } catch {
        console.error(`Unable to parse cookie value for - ${key}`);
        return null;
    }
}

function setPresumedLoginState() {
    var exdays = 30; // Expiration time of Cimpress Auth0 Cookie
    var plsCookieValue = 1;

    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = d.toUTCString();

    setCookie(plsCookieName, plsCookieValue, expires);
}

function getPresumedLoginState() {
    return getCookie(plsCookieName);
}

function deletePresumedLoginState() {
    var expires = "Thu, 01 Jan 1970 00:00:00 UTC";
    setCookie(plsCookieName, "", expires);
}

function setAnonymousIdentityState(id, nonce, anonKey) {
    var exdays = 1000;

    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = d.toUTCString();

    var state = {
        id: id,
        nonce: nonce,
        anonKey: anonKey
    };

    setCookie(anonCookieName, JSON.stringify(state), expires);
}

function getAnonymousIdentityState() {
    return getCookie(anonCookieName);
}

function deleteAnonymousIdentityState() {
    var expires = "Thu, 01 Jan 1970 00:00:00 UTC";
    setCookie(anonCookieName, "", expires);
}

function getOptimizelyId() {
    return getCookie(testCookieName, false) || getCookie(optimizelyCookieName, false);
}

function setAuthCookie (refreshToken) {
    var exdays = 30; // Expiration time of Cookie

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = d.toUTCString();

    setCookie(authCookieName, JSON.stringify(refreshToken), expires);
}

function getAuthCookie () {
    return getCookie(authCookieName);
}

function deleteAuthCookie () {
    var expires = "Thu, 01 Jan 1970 00:00:00 UTC";
    setCookie(authCookieName, "", expires);
}

function setSessionCookie (sessionId) {
    var exdays = 30; // Expiration time of Cookie (default sessionTTL is 30 days)

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = d.toUTCString();

    setCookie(sessionCookieName, sessionId, expires);
}

function getSessionCookie () {
    return getCookie(sessionCookieName, false);
}

function deleteSessionCookie () {
    var expires = "Thu, 01 Jan 1970 00:00:00 UTC";
    setCookie(sessionCookieName, "", expires);
}

function setConfigCookie (clientId, domain) {
    var exdays = 30; // Expiration time of Cookie

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = d.toUTCString();

    setCookie(configCookieName, JSON.stringify({ clientId, domain }), expires);
}

function getConfigCookie () {
    return getCookie(configCookieName);
}

function deleteConfigCookie () {
    var expires = "Thu, 01 Jan 1970 00:00:00 UTC";
    setCookie(configCookieName, "", expires);
}

module.exports = {
    setPresumedLoginState: setPresumedLoginState,
    deletePresumedLoginState: deletePresumedLoginState,
    getPresumedLoginState: getPresumedLoginState,
    setAnonymousIdentityState: setAnonymousIdentityState,
    getAnonymousIdentityState: getAnonymousIdentityState,
    deleteAnonymousIdentityState: deleteAnonymousIdentityState,
    getOptimizelyId: getOptimizelyId,
    setAuthCookie: setAuthCookie,
    getAuthCookie: getAuthCookie,
    deleteAuthCookie: deleteAuthCookie,
    getCookie: getCookie,
    setSessionCookie: setSessionCookie,
    getSessionCookie: getSessionCookie,
    deleteSessionCookie: deleteSessionCookie,
    setConfigCookie: setConfigCookie,
    getConfigCookie: getConfigCookie,
    deleteConfigCookie: deleteConfigCookie
}
