var WebAuth = require('./auth');
var init = require('./init');

// Do not modify the following lines.
// They will be populated during build time.
//#LOG_VERSION#
console.log('@vp/auth:6.1.191');
//#END#

exports.WebAuth = WebAuth
exports.init = init
