function parseState(state) {
  try {
    return JSON.parse(atob(decodeURIComponent(state)))
  } catch (err) {
    return { redirectUrl: state }
  }
}

function isCustomerConnection(deps) {
  return (new RegExp("-Customers$")
    .test(deps.options.connection) || new RegExp("-Unification$")
      .test(deps.options.connection))
}

function isAdfsConnection(deps) {
  return deps.options.connection.indexOf("-waad") !== -1;
}

module.exports = {
  parseState,
  isCustomerConnection,
  isAdfsConnection
};