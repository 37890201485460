var Events = require('./events');
var cookies = require('./cookies/cookies');
var StorageNotification = require('./storage/storageNotification.js');
var jwt = require('./helpers/jwt');
const constants = require('./helpers/constants.js');

var timer;
function CimpressAnonSession(storage, options) {
    if (!storage) {
        throw new Error('[CimpressAnonSession] Error: Storage layer not provided.')
    }
    if (!options) {
        throw new Error('[CimpressAnonSession] Error: Options not provided.')
    }

    this.storage = storage;
    this.options = options;
    this.cookies = cookies;
    this.events = new Events();
    this.hasInitialEventBeenPublished = false;
    this.storageNotification = new StorageNotification(storage, options);
    this.retryCount = 2
}

CimpressAnonSession.prototype.init = function (session) {
    this.session = session;
    this.storageNotification.subscribeOnce('user', this.storageChanged.bind(this));
    
    this.checkRefresh();
}

CimpressAnonSession.prototype.checkRefresh = function () {
    if (this.signedInOtherWindow) {
        this.signedInOtherWindow = null;
        return;
    }

    var anon = this.cookies.getAnonymousIdentityState();
    // No data for Anonymous token refresh present.
    if (!anon) {
        this.createAnonymousIdentity();
        return;
    }

    var token = this.storage.get(this.options.storageTokenKey);
    // Token is missing (from both local storage and cookie) but all data for token refresh is present.
    // Or the token was generated using old Anon service, go ahead and refresh the identity.
    if (anon.anonKey || !token) {
        this.refreshAnonymousIdentity(anon);
        return;
    }

    // Inspect existing token
    var tokenAge = (Date.now() - token.timestamp) / 1000;

    if (!jwt.isValidToken(token.value)) {
        // Anonymous token is about to expire / already expired.
        this.refreshAnonymousIdentity(anon);
    } else {
        // Anonymous token is valid, schedule next refresh.
        if (!this.hasInitialEventBeenPublished) {
            this.events.publishAnonymousIdentity(token.value);
            this.hasInitialEventBeenPublished = true;
        }
        this.schedule(this.options.tokenRefreshInterval - tokenAge);
    }
}

CimpressAnonSession.prototype.createAnonymousIdentity = function () {
    var xhr = new XMLHttpRequest();
    xhr.onload = this.createAnonymousIdentityCallback.bind(this, xhr);
    xhr.onerror = this.createAnonymousIdentityCallback.bind(this, xhr);

    xhr.open("POST", this.options.authFrontAnonymousEndpoint);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.send(JSON.stringify({
        client_id: this.options.oidc.clientID || constants.CIMPRESS_CLIENT,
        grant_type: 'https://cimpress.io/oauth/grant-type/anonymous',
        scope: 'offline_access',
        audience: this.options.oidc.audience
    }));
}

CimpressAnonSession.prototype.createAnonymousIdentityCallback = function (xhr) {
    if (xhr.status !== 200) {
        console.log('Unable to create token from Cimpress OAuth service. Status:' + xhr.status);
        //Try it Again
        const retryCodes = [408, 429, 500, 502, 503, 504, 522, 524];
        if (retryCodes.includes(xhr.status)) {
            if (this.retryCount === 0) {
                this.events.publishUserIdentityError({ message: xhr.responseText, statusCode: xhr.status })
                return;
            }
            this.retryCount = this.retryCount - 1;
            this.scheduleCreateAnonymousIdentity();
        }
        return;
    }
    // override anonkey
    this.processTokenResponse(xhr.response);
}

CimpressAnonSession.prototype.refreshAnonymousIdentity = function (anon) {
    var xhr = new XMLHttpRequest();
    xhr.onload = this.refreshAnonymousIdentityCallback.bind(this, xhr, anon);
    xhr.onerror = this.refreshAnonymousIdentityCallback.bind(this, xhr, anon);

    xhr.open("POST", this.options.authFrontAnonymousEndpoint);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.send(JSON.stringify({
        client_id: this.options.oidc.clientID || constants.CIMPRESS_CLIENT,
        grant_type: 'refresh_token',
        refresh_token: anon.nonce,
        ...(anon.anonKey ? {
            anonymous_id: anon.id,
            anonymous_key: anon.anonKey
        } : {})
    }));
}


CimpressAnonSession.prototype.refreshAnonymousIdentityCallback = function (xhr) {
    if (xhr.status !== 200) {
        console.log('Unable to refresh token from Cimpress OAuth service. Status:' + xhr.status);
        //Try it again
        this.schedule();
        return;
    }
    this.processTokenResponse(xhr.response);
}

CimpressAnonSession.prototype.processTokenResponse = function (response) {
    if (this.signedInOtherWindow) {
        this.signedInOtherWindow = null;
        return;
    }
    var body = JSON.parse(response);
    var token = {
        id: jwt.getCanonicalId(jwt.parse(body.access_token)),
        nonce: body.refresh_token,
        access_token: body.access_token,
        reference_token: body.alternative_access_token
    };

    this.storage.set(this.options.storageTokenKey, 'anonymous', token.access_token);
    this.cookies.setAnonymousIdentityState(token.id, token.nonce);
    if (token.reference_token) {
        this.cookies.setSessionCookie(token.reference_token);
    }

    this.schedule();
    this.events.publishAnonymousIdentity(token.access_token);
}

CimpressAnonSession.prototype.schedule = function (interval) {
    if (timer) {
        clearTimeout(timer);
    }
    var nextTick = interval || this.options.tokenRefreshInterval;
    timer = setTimeout(this.checkRefresh.bind(this), nextTick);
}

CimpressAnonSession.prototype.scheduleCreateAnonymousIdentity = function () {
    var nextTick = 30 * 1000;
    setTimeout(this.createAnonymousIdentity.bind(this), nextTick);
}

CimpressAnonSession.prototype.storageChanged = function () {
    this.signedInOtherWindow = true;
    this.hasInitialEventBeenPublished = false;
    this.session.init();
}

module.exports = CimpressAnonSession;
