var cookies = require('./cookies/cookies');
var consts = require('./helpers/constants');

const validNavHintValues = ['signUp'];
function AuthOptionsBuilder (options) {
    this.connection = options.connection;
    this.site = options.site;
    this.culture = options.culture;
    this.allowGuestUser = options.allowGuestUser;
    this.guestReturnUrl = options.guestReturnUrl;
    this.clientId = options.oidc && options.oidc.clientID;
    this.domain = options.oidc && options.oidc.domain;
    this.hasAnonymousId = false;
    this.hasCulture = false;
    this.skipFasterCheckoutText = options.skipFasterCheckoutText === true;
    this.loginContext = options.loginContext;
    this.navHint = options.navHint;
    this.originUrl = String(window.location.href).split('?')[0];
    this.requireSession = options.requireSession === true;
    this.customText = options.customText;
    this.enableGoogleOneTap = options.enableGoogleOneTap === true;
    this.enforcedEmail = options.enforcedEmail;
    this.isFirstLogin = options.isFirstLogin === true;
    this.isSteppedUp = options.isSteppedUp === true;
    this.unificationEntityId = options.unificationEntityId;
    this.vcsStoreUrl = options.vcsStoreUrl
    this.testUserId = options.testUserId
    this.storeId = options.storeId
    this.vcsStoreLogoUrl = options.vcsStoreLogoUrl
    this.restrictSignup = options.restrictSignup === true;
    this.disableEmailField = options.disableEmailField === true;
    this.anonReferenceToken = undefined;
}

AuthOptionsBuilder.prototype.withReturnUrl = function (returnUrl) {
    if (!returnUrl) {
        throw new Error('[AuthOptionsBuilder] Error: ReturnUrl is not provided.')
    }

    this.returnUrl = returnUrl;
    return this;
}

AuthOptionsBuilder.prototype.withAnonymousId = function (token) {
    this.hasAnonymousId = true;
    if (token && !token.match(consts.SESSION_ID_REGEX)) {
        this.anonReferenceToken = token;
    }
    return this;
}

AuthOptionsBuilder.prototype.withCulture = function () {
    this.hasCulture = true;
    return this;
}

function defaultSite () {
    var location = window.location || {};
    var host = location.host || '';
    var tokens = host.split('.');
    var domainMapping = {
        'promotique': 'Promotique',
        '99designs': '99designs',
        'vistacreate': 'vistacreate'
    }
    var domains = Object.keys(domainMapping)
    for (var i = 0; i < tokens.length; i++) {
        if (domains.includes(tokens[i])) {
            return domainMapping[tokens[i]];
        }
    }
    return 'Vistaprint';
}

function buildWAuth (hasAnonymousId, hasCulture, culture, hasGuestUser, guestReturnUrl, returnUrl, site, skipFasterCheckoutText, loginContext, navHint, originUrl, customText, enableGoogleOneTap, enforcedEmail, isFirstLogin, unificationEntityId, vcsStoreUrl, testUserId, storeId, vcsStoreLogoUrl, isSteppedUp, anonReferenceToken, restrictSignup, disableEmailField) {
    var wauth = {
        site: site || defaultSite()
    };

    if (hasAnonymousId) {
        const anon = cookies.getAnonymousIdentityState();
        if (anon) {
            if (anonReferenceToken) {
                wauth.anonReferenceToken = anonReferenceToken;
                wauth.anonymousId = encodeURIComponent(anon.id);
            } else {
                wauth.anonymousId = encodeURIComponent(anon.id);
                wauth.validationKey = encodeURIComponent(anon.nonce);
                wauth.anonKey = anon.anonKey && encodeURIComponent(anon.anonKey);
            }
        }
    }

    var optimizelyId = cookies.getOptimizelyId();
    if (optimizelyId) {
        wauth.optimizelyId = optimizelyId;
    }
    // override the optimizelyId when the testUserId passed
    if (testUserId) {
        wauth.optimizelyId = testUserId;
    }
    if (storeId) {
        wauth.storeId = storeId;
    }

    if (hasCulture && culture) {
        wauth.culture = culture;
    }
    
    if (returnUrl) {
        wauth.redirectUrl = returnUrl;
    }

    if (hasGuestUser) {
        wauth.guestReturnUrl = guestReturnUrl ? guestReturnUrl : returnUrl;
    }

    if (skipFasterCheckoutText) {
        wauth.skipFasterCheckoutText = true;
    }

    if (loginContext) {
        wauth.loginContext = loginContext;
    }

    if (navHint) {
        if (validNavHintValues.includes(navHint)) {
            wauth.navHint = navHint;
        } else {
            console.error("Invalid nav hint value passed - ", navHint);
        }
    }

    if (originUrl) {
        wauth.originUrl = originUrl;
    }

    var gaCookie = cookies.getCookie('_ga', false);
    if (gaCookie) {
        wauth.ga = gaCookie;
    }

    var visitCookie = cookies.getCookie('visit', false);
    if (visitCookie) {
        wauth.visit = visitCookie;
    }

    if(customText){
        wauth.customText = customText;
    }

    if(enableGoogleOneTap) {
        wauth.enableGoogleOneTap = enableGoogleOneTap;
    }

    if(enforcedEmail) {
        wauth.enforcedEmail = enforcedEmail;
    }

    if(isFirstLogin) {
        wauth.isFirstLogin = isFirstLogin;
    }

    if (isSteppedUp) {
        wauth.isSteppedUp = isSteppedUp;
    }

    if(unificationEntityId) {
        wauth.unificationEntityId = unificationEntityId;
    }

    if(vcsStoreUrl) {
        wauth.vcsStoreUrl = vcsStoreUrl;
    }

    if (vcsStoreLogoUrl) {
        wauth.vcsStoreLogoUrl = vcsStoreLogoUrl;
    }

    if (restrictSignup) {
        wauth.restrictSignup = restrictSignup;
    }

    if (disableEmailField) {
        wauth.disableEmailField = disableEmailField;
    }

    return btoa(JSON.stringify(wauth));
}

function buildState (redirectUrl, wauth, clientId, requireSession, domain) {
    // pass the wauth so that it can be used by the Auth Callback page when handling login errors
    // note: wauth may not be present for some connections (eg. ADFS)
    var state = {
        redirectUrl: redirectUrl,
        wauth: wauth,
        clientId: clientId,
        requireSession: requireSession,
        domain: domain
    };

    return btoa(JSON.stringify(state));
}

AuthOptionsBuilder.prototype.build = function () {
    if (!this.returnUrl) {
        throw new Error('[AuthOptionsBuilder] Error: ReturnUrl was not set up.')
    }

    var wauth;
    if (this.connection.indexOf("-waad") === -1) {
        wauth = buildWAuth(this.hasAnonymousId,
            this.hasCulture,
            this.culture,
            this.allowGuestUser,
            this.guestReturnUrl,
            this.returnUrl,
            this.site,
            this.skipFasterCheckoutText,
            this.loginContext,
            this.navHint,
            this.originUrl,
            this.customText,
            this.enableGoogleOneTap,
            this.enforcedEmail,
            this.isFirstLogin,
            this.unificationEntityId,
            this.vcsStoreUrl,
            this.testUserId,
            this.storeId,
            this.vcsStoreLogoUrl,
            this.isSteppedUp,
            this.anonReferenceToken,
            this.restrictSignup,
            this.disableEmailField);
    }

    return {
        connection: this.connection,
        state: buildState(this.returnUrl, wauth, this.clientId, this.requireSession, this.domain),
        wauth: wauth
    };
}

module.exports = AuthOptionsBuilder;
