module.exports = function(obj1, obj2) {
    if (typeof Object.assign === 'function') {
        return Object.assign({}, obj1, obj2);
    }

    var result = [obj1, obj2].reduce(function (r, o) {
            if(o === null || o === undefined) { return r; }
            Object.keys(o).forEach(function(k) { r[k] = o[k]; });
            return r;
        }, {});

    return result;
}
