function StorageNotification(storage, options) {
    this.storage = storage;
    this.options = options;
}

StorageNotification.prototype.subscribeOnce = function (newTokenType, callback) {
    this.storageChangedBound = this.storageChanged.bind(this, newTokenType, callback);
    this.storage.addStorageChangedInOtherWindowEventListener(this.storageChangedBound);
}

StorageNotification.prototype.storageChanged = function (newTokenType, callback, event) {
    if (event.key !== this.options.storageTokenKey) {
        return;
    }
    const token = parseToken(event.newValue);
    if (!token) {
        return;
    }
    if (token.type !== newTokenType) {
        return;
    }
    this.storage.removeStorageChangedInOtherWindowEventListener(this.storageChangedBound);
    callback();
}

function parseToken(json) {
    if (!json) {
        return null;
    }
    try {
        return JSON.parse(json);
    }
    catch (_) {
        return null;
    }
}

module.exports = StorageNotification;



