/**
 * Language subtag validation.
 *
 * Read: https://www.w3.org/International/articles/language-tags/
 *
 * Format:
 *  - 2 or 3 aplha code
 *  - convention: lower case
 *  - https://en.wikipedia.org/wiki/ISO_639
 *
 * Valid subtag registry:
 *  - http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 */
function isLanguageValid(language) {
    return !!language.match(/^[a-zA-Z]{2,3}$/);
}

/**
 * Script subtag validation.
 *
 * Read: https://www.w3.org/International/articles/language-tags/
 *
 * Format:
 *  - 4 letter code
 *  - convention: capitalized
 *  - https://en.wikipedia.org/wiki/ISO_15924
 *
 * Valid subtag registry:
 *  - http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 */
function isScriptValid(script) {
    return !!script.match(/^[a-zA-Z]{4}$/);
}

/**
 * Region subtag validation.
 *
 * Read: https://www.w3.org/International/articles/language-tags/
 *
 * Format:
 *  - 2 aplha or 3 digit code
 *  - convention: upper case
 *  - https://en.wikipedia.org/wiki/ISO_3166
 *
 * Valid subtag registry:
 *  - http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 */
function isRegionValid(region) {
    return !!region.match(/^[a-zA-Z]{2}$/) || !!region.match(/^[0-9]{3}$/);
}

/**
 * Culture code (language tag) validation.
 *
 * Read: https://www.w3.org/International/articles/language-tags/
 *
 * Valid format:
 *  - language-region
 *  - language-script-region
 */
function isValid(languageTag) {
    if (!languageTag) return false;

    var subtags = languageTag.split('-');

    if (subtags.length === 2) {
        // language-region
        return isLanguageValid(subtags[0]) && isRegionValid(subtags[1]);
    } else if (subtags.length === 3) {
        // language-script-region
        return isLanguageValid(subtags[0]) && isScriptValid(subtags[1]) && isRegionValid(subtags[2]);
    }

    return false;
}

/**
 * Convert valid culture to convention accepted by Auth0.
 * Otherwise return whatever was passed.
 */
function convertToConvention(languageTag) {
    if (!isValid(languageTag)) { return languageTag; }

    var subtags = languageTag.split('-');

    if (subtags.length === 2) {
        // language-region
        return [
            subtags[0].toLowerCase(),
            subtags[1].toUpperCase()
        ].join('-');
    } else if (subtags.length === 3) {
        // language-script-region
        return [
            subtags[0].toLowerCase(),
            subtags[1].charAt(0).toUpperCase() + subtags[1].slice(1).toLowerCase(),
            subtags[2].toUpperCase()
        ].join('-');
    }
}

module.exports = {
    isCultureCodeValid: isValid,
    convertToConvention: convertToConvention
}
