var consts = require('./helpers/constants');

function getTenantConfiguration() {
    return {
        clientId: consts.CIMPRESS_CLIENT,
        connection: 'VP-Customers',
        defaultSite: 'Vistaprint',
        domain: consts.CIMPRESS_DOMAIN
    }
}

module.exports = {
    getTenantConfiguration: getTenantConfiguration
};
