function isLocalStorageAvailable(){
    var test = 'test';
    try {
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

function getLocalStorage() {
    return isLocalStorageAvailable()
        ? {
            getItem: window.localStorage.getItem.bind(window.localStorage),
            setItem: window.localStorage.setItem.bind(window.localStorage),
            removeItem: window.localStorage.removeItem.bind(window.localStorage),
            addStorageChangedInOtherWindowEventListener,
            removeStorageChangedInOtherWindowEventListener
        }
        : null;
};

function addStorageChangedInOtherWindowEventListener(callback){
    window.addEventListener('storage', callback);
}

function removeStorageChangedInOtherWindowEventListener(callback){
    window.removeEventListener('storage', callback);
}

module.exports = {
    getStorage: getLocalStorage,
    isAvailable: isLocalStorageAvailable
}
