var Session = require('./session');
var Storage = require('./storage/storage');
var AnonymousSession = require('./anonymousSession');
const CimpressAnonSession = require('./cimpressAnonSession');
var WebAuth = require('./auth');

var settings = require('./settings');
var callbackMock = require('./mocks/callback');
var globals = require('./globals');
var merge = require('./helpers/merge');
var cultureValidator = require('./culture');
const cookies = require('./cookies/cookies');
const constants = require('./helpers/constants');
const utils = require('./helpers/utils');

function init (configuration, callback, errorCallback) {
    var dependenciesNotInitialized = !globals.isDepsInitialized();

    if (dependenciesNotInitialized) {
        applyOverrides(configuration, callback, errorCallback);
    } else {
        // For already initialized user identity callback will be executed immediately.
        // Otherwise it will be registered for the userIdentity event.
        registerCustomInitCallback(callback, errorCallback);
    }
}

function applyOverrides(configuration, callback, errorCallback) {
    var fragments;

    if (window.location.search) {
        fragments = window.location.search
            .substring(1)
            .split('&')
            .reduce(function (prev, cur) {
                var kv = cur.split('=');
                prev[kv[0]] = kv[1];
                return prev;
            }, {});
    }

    if (fragments && (fragments.overrides || (fragments.code && fragments.state))) {
        var overrides = {
            useAnonSessionV2: false
        };

        if (fragments.overrides) {
            overrides = {
                useAnonSessionV2: true
            };
        }

        runInitializations(configuration, callback, overrides, fragments, errorCallback);
    } else {
        var xhr = new XMLHttpRequest();
        xhr.onload = overridesCallback.bind(this, xhr, configuration, callback, fragments, errorCallback);
        xhr.onerror = overridesCallback.bind(this, xhr, configuration, callback, fragments, errorCallback);

        xhr.open("GET", `https://oauth.cimpress.io/overrides.json`);
        xhr.send(null);
    }
}

function overridesCallback(xhr, configuration, callback, fragments, errorCallback) {
    var overrides = {
        useAnonSessionV2: false
    };

    if (xhr.status === 200) {
        let overridesResponse = JSON.parse(xhr.response);
        if (overridesResponse) {
            if (overridesResponse.useAnonSessionV2 &&
                (overridesResponse.useAnonSessionV2.includes(window.location.hostname) || overridesResponse.useAnonSessionV2.includes('*'))
            ) {
                overrides.useAnonSessionV2 = true;
            }
        }
    }

    runInitializations(configuration, callback, overrides, fragments, errorCallback);
}

function runInitializations(configuration, callback, overrides, fragments, errorCallback) {
    var dependenciesNotInitialized = !globals.isDepsInitialized();
    if (dependenciesNotInitialized) {
        // Only first call will initialize configurations, cache and dependencies.
        initDependencies(configuration, overrides);
        initUserIdentityUpdateCache();
        registerCustomInitCallback(callback, errorCallback);

        // Only first call will initialize session - token refreshes.
        // Other calls will reuse already initialized session.
        var deps = globals.getDeps();
        if (deps.session) {
            deps.session.init();
        }

        if (fragments && fragments.askUserToLogin) {
            const auth = new WebAuth();
            auth.signIn([], [], {});
        }
    } else {
        // For already initialized user identity callback will be executed immediately.
        // Otherwise it will be registered for the userIdentity event.
        registerCustomInitCallback(callback, errorCallback);
    }
}

function initDependencies (configuration, overrides) {
    var options = createOptions(configuration, overrides);

    // Dependency: Storage - default is localStorage.
    var storage = new Storage();

    // Development mode: local OAuth2 redirect uri callback handler.
    if (configuration.developmentMode) {
        // To support PKCE flow in callback
        callbackMock(storage, options);
    }

    // Dependency: AnonymousSession
    var anonCookie = cookies.getAnonymousIdentityState();
    var anon;
    if (!utils.isAdfsConnection({ options })) {
        anon = (overrides.useAnonSessionV2 || (anonCookie && !anonCookie.anonKey)) ? new CimpressAnonSession(storage, options) : new AnonymousSession(storage, options);
    }
    
    // Dependency: Session
    var session = new Session(anon, storage, options);

    globals.setDeps({
        options: options,
        storage: storage,
        anon: anon,
        session: session
    });
}

function initUserIdentityUpdateCache () {
    window.addEventListener('userIdentity', function (e) {
        globals.setCache(e.detail);
    });
}

function registerCustomInitCallback (callback, errorCallback) {
    if (typeof callback !== 'function') { return; }

    if (globals.isCacheInitialized()) {
        callback(globals.getCache());
    } else {
        window.addEventListener('userIdentity', function onInit (e) {
            window.removeEventListener('userIdentity', onInit, false);
            callback(e.detail);
        });

        if (typeof errorCallback === 'function') {
            window.addEventListener('userIdentityError', function onInitError (e) {
                window.removeEventListener('userIdentityError', onInitError, false);
                errorCallback(e.detail);
            });
        }

    }
}

function createOptions (configuration, overrides) {
    if (!configuration) {
        throw new Error('[@vp/auth#init] Configuration must be provided.');
    }

    if (!cultureValidator.isCultureCodeValid(configuration.culture)) {
        throw new Error('[@vp/auth#init] Configuration must contain a valid culture code.');
    }

    configuration.options = configuration.options || {};

    if (configuration.options.redirectAfterSignOutCallback != null
        && !(configuration.options.redirectAfterSignOutCallback instanceof Function)) {
        throw new Error('[@vp/auth#init] redirectAfterSignOutCallback must be a function.');
    }

    configuration.options.culture = cultureValidator.convertToConvention(configuration.culture);
    configuration.options.enableCrossSiteSso = !!configuration.enableCrossSiteSso;

    configuration.developmentMode = configuration.developmentMode || false;

    var result = merge(
        settings.create(configuration.developmentMode),
        configuration.options
    );

    if (configuration.options.clientID) {
        result.oidc.clientID = configuration.options.clientID;
        
        // in case the clientId was provided but the domain wasn't provided, then we fall back to using auth0.
        if (!configuration.developmentMode || !configuration.developmentMode.domain) {
            result.oidc.domain = constants.AUTH0_DOMAIN;
        }
    }

    result.overrides = overrides;

    return result;
}

module.exports = init;
